import React, {useEffect, useState} from 'react';

// @ts-ignore
import Modal from "react-modal";
import {IoMdClose} from "react-icons/io";
import * as Api from '../../helper/serverApi';
import '../../styles/css/modal.scss';
import {KakaoAddrState, NaverMapAddrData} from "../../pages/MapPage";
import {CampDAO, CampParams} from "../../helper/serverApi";


interface RegCampModalParams {
  isShow: boolean,
  naAddr: NaverMapAddrData,
  kaAddr: KakaoAddrState,
  close(): void,
}


Modal.setAppElement('#root');
const RegCampModal = (props: RegCampModalParams): any => {
  const {isShow, naAddr, kaAddr, close} = props;
  const kJAddress = kaAddr.jAddr.hasOwnProperty('address') ? kaAddr.jAddr : '';
  const kRAddress = kaAddr.rAddr.hasOwnProperty('address') ? kaAddr.rAddr : '';
  // console.log('지번 주소 ------')
  // console.log(kJAddress)
  // console.log('도로명 주소 ------')
  // console.log(kRAddress)

  const regCamp = () => {
    // const cp: CampParams = {} as CampParams;
    const cp: CampParams = new CampDAO();
    cp.campName = '캠핑장명칭';
    cp.pnuCode = kaAddr.pnuCode!;
    cp.lat = naAddr.lat;
    cp.lng = naAddr.lng;
    cp.jibunAddress = naAddr.address;
    cp.sidoName = naAddr.sido!;
    cp.sggName = naAddr.sigungu!;
    cp.dongName = naAddr.dong!;
    cp.riName = naAddr.ri!;
    cp.roadAddress = kRAddress !== '' ? kRAddress.address : '';
    cp.zipCode = kRAddress !== '' ? kRAddress.zoneCode : '';

    Api.addCamp_API(cp).then( (res : any) => {
      console.log(res)
      close()
    })
  }

  return (
    <Modal
      isOpen={isShow}
      // onRequestClose={() => setToggleReqReportModal(false)}
      className="modalBase2 reqReportModal"
      overlayClassName="Overlay"
    >
      <div className={'regCampWrapper'}>
        <div className={'headerBase'}>
          <span className={'titleText'}>캠핑장등록</span>
          <div className='baseBtn closeBtn' onClick={close}>
            <IoMdClose
              size={27}
            />
          </div>
        </div>
        <div className={'bodyBase'}>
          <div>{naAddr.address}</div>
          <div>{naAddr.sido}</div>
          <div>{naAddr.sigungu}</div>
          <div>{naAddr.dong}</div>
          <div>{naAddr.lat}, {naAddr.lng} </div>
        </div>
        <div className={'footerBase'}>
          <div className={'baseBtn cancelBtn'} onClick={close}>취소</div>
          <div className={'baseBtn doBtn'} onClick={regCamp}>확인</div>
        </div>
      </div>
    </Modal>
  )
}

export default RegCampModal
