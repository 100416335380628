// 테스트용 랜덤 마커
export const randomTestMarker: (num: number) => any = (num) => {
  const tempNum = num;
  const tempArr = [];

  for (let i = 0; i < tempNum; i++) {
    // 37.476821
    // 126.886972
    const rLat = Math.floor(Math.random() * 999) + 100;
    const rLng = Math.floor(Math.random() * 999) + 100;
    const tempObj = {
      camp_id: i,
      lat: `37.4${rLat}`,
      lng: `126.8${rLng}`
    }
    tempArr.push(tempObj)
  }
  return tempArr
}


// PNU CODE 생성
export const pnuCodeString = (bCode: string | undefined, jiCode: string | undefined, bunCode: string | undefined) => {
  if (bCode && jiCode && bunCode) {
    return `${bCode}1${jibunString(jiCode, 4)}${jibunString(bunCode, 4)}`
  }
}

// 지번을 4자리 문자열로 만든다.
export const jibunString = (code: string, width: number): string => {
  return code.length >= width ? code : new Array(width - code.length + 1).join('0') + code;
}


export const getRefreshToken = (): string | null => {
  return localStorage.getItem('refresh_token');
}

export const getAccessToken = (): string | null => {
  return localStorage.getItem('access_token');
}

export const setAccess_token = (access_token: string) : void => {
  localStorage.setItem('access_token', access_token);
}

interface tokenParams {
  access_token: string,
  refresh_token: string,
}

export const setToken = (tokenObj: tokenParams) => {
  localStorage.setItem('access_token', tokenObj.access_token);
  localStorage.setItem('refresh_token', tokenObj.refresh_token);
}

export const clearToken = (): void => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
}

