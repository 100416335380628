export const SERVER_URL = 'https://campinglive.kr';



export const API_URL = `${SERVER_URL}/api`;
export const PREFIX_ROUTE = '/app'


export const DAUMAPIKEY = 'cae373c9f88d6cec85ce81e007987dab';
export const KAKAO_ADDRESS = 'https://dapi.kakao.com/v2/local/search/address.json';  // 주소로 정보얻기
export const KAKAO_GEO_ADDRESS = 'https://dapi.kakao.com/v2/local/geo/coord2address.json';  // 좌표로 주소로 정보얻기
