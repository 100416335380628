import React, {useEffect, useState} from 'react';
import {isMobile} from "react-device-detect";

// @ts-ignore-start
import {NaverMap} from 'react-naver-maps';
// @ts-ignore-end
import * as Api from '../helper/serverApi';
import * as Utils from '../helper/utils'
import MapControlView from "../components/MapControlView";
import Markers from "../components/Markers";
import RegCampModal from "../components/modal/RegCampModal";
import PublicMarkers from "../components/PublicMarkers";
// @ts-ignore
const naverMaps = window.naver.maps;


// interface MarkerItem {
//   camp_id: string,
//   lat: string,
//   lng: string,
// }
//
// interface MarkerItems extends Array<MarkerItem> {
// }

export interface KakaoAddrState {
  jAddr: JiBunAddrData,
  rAddr: RoadAddrData,
  pnuCode?: string,
}

export interface JiBunAddrData extends AddressData {
  bCode?: string,
  hCode?: string,
  mainNo?: string,
  subNo?: string,
  mountain?: string,
}

export interface RoadAddrData extends AddressData {
  buildingName?: string,
  mainBdNo?: string,
  subBdNo?: string,
  roadName?: string,
  underGround?: string,
  zoneCode?: string,
}

export interface AddressData {
  address?: string,
  sido?: string,
  sigungu?: string,
  dong?: string,
  ri?: string,
  lng: string,
  lat: string,
}

export interface NaverMapAddrData {
  country: string,
  sido: string,
  sigungu: string,
  dong: string,
  ri: string,
  etc: string,
  address: string,
  lat: string,
  lng: string,
}

export const DefaultAddressData = {
  address: '',
  bCode: '',
  hCode: '',
  mountain: '',
  sido: '',
  sigungu: '',
  dong: '',
  ri: '',
  mainNo: '',
  subNo: '',
  lng: '',
  lat: '',
}

export const DefaultAddressData2 = {
  address: '',
  sido: '',
  sigungu: '',
  dong: '',
  ri: '',
  lng: '',
  lat: '',
  buildingName: '',
  mainBdNo: '',
  subBdNo: '',
  roadName: '',
  underGround: '',
  zoneCode: '',
}

export const DefaultNaverAddr = {
  country: '',
  sido: '',
  sigungu: '',
  dong: '',
  ri: '',
  etc: '',
  address: '',
  lat: '',
  lng: '',
}

type MarkerItem = {
  campId: number,
  userId: number,
  publicType: string,
  photo: string,
  lat: string,
  lng: string,
}

type MarkerType = 'UNIT'  | 'CLUSTER'
type OriginType = 'SD' | 'SG'
type PublicMarkerItem = {
  contentId: number | string,
  markerType: MarkerType,
  originType: OriginType,
  title: string,
  photo: string,
  data: object,
  lat: string,
  lng: string,
}

export type DiaryType = {
  code: string,
  name: string,
}

// type MarkerShowType =
//   | 'ALL' | 'MY'

const MarkerItems: MarkerItem[] = []


const MapPage = () => {
  //@ts-ignore
  window.receivedMessageFromReactNative = (type: string, data: any) => {
    nativeToJs(type, data)
  };
  const [mapRef, setMapRef] = useState('');
  // @ts-ignore
  const {map} = mapRef;

  const [isLoaded, setIsLoaded] = useState(false);
  const [zoom, setZoom] = useState(7);
  const [minZoom, setMinZoom] = useState(7);
  const [maxZoom, setMaxZoom] = useState(20);
  // const [center, setCenter] = useState({lat: 37.476821, lng: 126.886972}); // 가산
  // const [center, setCenter] = useState({lat: 37.615124, lng: 126.512096}); // 인천
  // const [center, setCenter] = useState({lat: 36.806758, lng: 127.881940}); // 인천
  // const [center, setCenter] = useState({lat: 37.4139174160822, lng: 126.909342773577}); // 석수2동
  const [center, setCenter] = useState({lat: 36.4302409, lng: 127.6893721}); // 중심
  // const [center, setCenter] = useState({lat: 37.8800594, lng: 128.7344964}); // 중심
  // const [center, setCenter] = useState({lat: 37.7102513, lng: 128.7871994}); // 대관령 자연휴양림

  // Modal
  const [isShowRegCamp, setIsShowRegCamp] = useState(false);

  // MapControl
  const [isAllMarkerShow, setIsAllMarkerShow] = useState(true);
  const [isMyMarker, setIsMyMarker] = useState(false);
  const [isPublicMarker, setIsPublicMarker] = useState(true);  // 공공데이터 캠핑장
  const [diaryType, setDiaryType] = useState({code:'MY',name:'마이'})
  const [isSatellite, setIsSatellite] = useState(false);
  const [totalMarker, setTotalMarker] = useState({isShow: false});   // 전체 마커 숨김
  const [myLocation, setMyLocation] = useState({isMyLocation: false, isMyLocationLoaded: false, myLocationMarker: ''});
  const [overlayType, setOverlayType] = useState({isCadastralFullLayer: false, cadastralFullLayer: ''});
  const [markerData, setMarkerData] = useState(MarkerItems);
  const [publicMarkerData, setPublicMarkerData] = useState<PublicMarkerItem[]>([])
  const [spotMarker, setSpotMarker] = useState('');

  // Address
  // Naver Map Address
  const [naAddr, setNaAddr] = useState(DefaultNaverAddr)
  // KAKAO Address
  // 네이버맵 좌표를 기준으로 생성된 주소를 가지고 카카오 정보를 다시 호출해서 저장한다. 카카오 정보가 더 자세하다.
  const [kaAddr, setKaAddr] = useState({jAddr: DefaultAddressData, rAddr: DefaultAddressData2, pnuCode: ''})

  // To Native Call  Start ------------------------------------------------------------------------------------------------
  type RegCampNative = 'OPEN' | 'DATA'
  const regCampNativeCall = (type: RegCampNative = 'OPEN', data: any) => {
    let message: string;
    if (type === 'OPEN') {
      message = 'REG_CAMP_OPEN';
      try {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify({
          data: '',
          message: message,
        }));
      } catch (err) {
        console.log(err)
      }
    } else {
      message = 'REG_CAMP_DATA';
      try {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(JSON.stringify({
          data: data,
          message: message,
        }));
      } catch (err) {
        console.log(err)
      }
    }
  }

  const campDetailCall = (id: number) => {
    let message = 'CAMP_DETAIL';
    try {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({
        id: id,
        message: message,
      }));

    } catch (err) {
      console.log(err)
    }
  }

  // 개별 호출
  const publicCampDetailCall = (id: number) => {
    let message = 'PUBLIC_CAMP_DETAIL';
    try {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({
        id: id,
        message: message,
      }));

    } catch (err) {
      console.log(err)
    }
  }


  // 지역 호출 ( 네이티브에서 지역명을 받아서 리스트 조회한다. )
  type originParams = {
    originType: string,
    originName: string,
  }
  const publicCampClusterCall = (params: originParams) => {
    let message = 'PUBLIC_CAMP_ORIGIN';
    try {
      // @ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify({
        originType: params.originType,
        originName: params.originName,
        message: message,
      }));

    } catch (err) {
      console.log(err)
    }
  }


  // ......................................................................................................................
  // To Native Call End ---------------------------------------------------------------------------------------------------

  // To Web Call Start ----------------------------------------------------------------------------------------------------
  const nativeToJs = async (type: string, data?: any) => {
    switch (type) {
      case 'MARKER_RELOAD':
        getCampData()
        removeSpotMarker()
        break;
      case 'SET_PUBLIC':
        setIsPublicMarker(true)
        break;
      case 'SET_TOKEN':
        injectSetToken(data)
        break;
      case 'CLEAR_TOKEN':
        injectClearToken()
        break;
      case 'REMOVE_SPOT_MARKER':
        removeSpotMarker()
        break;
      case 'SET_LOCATION':
        await injectSetPosition(data)
        break;
      case 'DEFAULT':
        // console.log(type)
        break;
      default:
        break;
    }
  };
  // ......................................................................................................................
  // To Web Call End ------------------------------------------------------------------------------------------------------

  const injectSetToken = (token: any) => {
    // console.log('setToken')
    const obj = JSON.parse(token);
    // const { access_token, refresh_token } = obj
    Utils.setToken(obj)
  }

  const injectClearToken = () => {
    // console.log('clearToken')
    Utils.clearToken()
  }

  interface positionParams {
    lat: number,
    lng: number,
  }
  const injectSetPosition = async (pos: string) : Promise<void> => {
    const position : positionParams = JSON.parse(pos);
    setCenter({lat: position.lat, lng: position.lng})
    const latLng = new naverMaps.LatLng(position.lat, position.lng)
    await map.setZoom(15)
    await map.setCenter(latLng)
    removeSpotMarker()
  }

  const addSpotMarker = (lat: string, lng: string) => {
    if (spotMarker.hasOwnProperty('map')) {
      // @ts-ignore
      spotMarker.setMap(null);
      setSpotMarker('');
    }
    const markerContent = [
      '<div class="pin redPin"></div>',
      '<div class="pulse"></div>',
    ].join('')
    const MarkerOptions: any = {
      position: new naverMaps.LatLng(lat, lng),
      map: map,
      icon: {
        content: markerContent
      }
    };
    const tempMarker = new naverMaps.Marker(MarkerOptions)
    setSpotMarker(tempMarker)
  }

  const removeSpotMarker = () => {
    if (spotMarker.hasOwnProperty('map')) {
      // @ts-ignore
      spotMarker.setMap(null);
      setSpotMarker('');
    }
  }

  const handleMapClick = async (e: any) => {
    // (기능개선)데이터를 통신한후 모달을 오픈하면 너무 느려서 일단 OPEN 후 데이터 바인딩
    if(zoom < 14) {
      const nowZoom = map.getZoom()
      setZoom(nowZoom + 2)
      await map.setZoom(nowZoom + 2, true)
      await map.panTo(e.latlng)
      return;
    }

    if (isMobile) {
      regCampNativeCall('OPEN', '')
    }
    getNaverAddr(e, async function (data: any) {
      const {address, lat, lng} = data;
      if (address === '대한민국') {
        alert('올바른 선택 지역이 아닙니다.')
        return
      }
      addSpotMarker(lat, lng)

      if (isMobile) {
        regCampNativeCall('DATA', Object.assign(await setKakaoAddress(address), {navAddr: data}))
      } else {
        setIsShowRegCamp(true)
      }
    });
  }

  const handleClickPlus = () => {
    // @ts-ignore
    const nowZoom = map.getZoom()
    if (maxZoom > nowZoom) {
      setZoom(nowZoom + 1)
      // @ts-ignore
      map.setZoom(nowZoom + 1, true)
    }
  };

  const handleClickMinus = () => {
    // @ts-ignore
    const nowZoom = map.getZoom()
    if (minZoom < nowZoom) {
      setZoom(nowZoom - 1)
      // @ts-ignore-end
      map.setZoom(nowZoom - 1, true)
    }
  };


  // 지적도
  const handleToggleCadastral = () => {
    let cadastralLayer = new naverMaps.CadastralLayer();
    if (overlayType.isCadastralFullLayer) {
      // @ts-ignore
      overlayType.cadastralFullLayer.setMap(null)
      setOverlayType({cadastralFullLayer: '', isCadastralFullLayer: false})
    } else {
      cadastralLayer.setMap(map)
      setOverlayType({cadastralFullLayer: cadastralLayer, isCadastralFullLayer: true})
    }
  };

  // 위성도
  const handleToggleSatelliteLayer = () => {
    if (map.getMapTypeId() === 'satellite') {
      map.setMapTypeId(naverMaps.MapTypeId['NORMAL']);
      setIsSatellite(false)
    } else {
      map.setMapTypeId(naverMaps.MapTypeId['SATELLITE']);
      setIsSatellite(true)
    }
  };

  // 카카오지도 주소정보
  const setKakaoAddress = (address: string) => {
    // 카카오 API 통해 pnuCode 외 정보 조회
    if (!address) {
      return false;
    }

    return Api.getAddressDataFromKakao_API({address: address})
      .then((res: any) => {
        const resultJaAddress: JiBunAddrData = {} as JiBunAddrData;
        const resultRdAddress: RoadAddrData = {} as RoadAddrData;
        const result = res.data.documents.length > 0 ? res.data.documents[0] : '';

        if (!result) {
          // console.log('카카오 결과 값이 없음')
          return {jAddr: resultJaAddress, rAddr: resultRdAddress, pnuCode: ''}
        }

        const jbAddressData = result.hasOwnProperty('address') ? result.address : '';
        const roadAddressData = result.hasOwnProperty('road_address') ? result.road_address : '';


        // 지번주소
        if (jbAddressData !== '' && jbAddressData !== undefined && jbAddressData !== null) {
          resultJaAddress.address = jbAddressData.address_name;
          resultJaAddress.bCode = jbAddressData.b_code;
          resultJaAddress.hCode = jbAddressData.h_code;
          resultJaAddress.mountain = jbAddressData.mountain_yn;
          resultJaAddress.sido = jbAddressData.region_1depth_name;
          resultJaAddress.sigungu = jbAddressData.region_2depth_name;
          // resultJaAddress.dong = jbAddressData.region_3depth_h_name;
          resultJaAddress.dong = jbAddressData.region_3depth_name;
          resultJaAddress.ri = jbAddressData.region_3depth_name;
          resultJaAddress.mainNo = jbAddressData.main_address_no;
          resultJaAddress.subNo = jbAddressData.sub_address_no;
          resultJaAddress.lng = jbAddressData.x;
          resultJaAddress.lat = jbAddressData.y;
        }

        // 도로명주소
        if (roadAddressData !== '' && roadAddressData !== undefined && roadAddressData !== null) {
          resultRdAddress.address = roadAddressData.address_name;
          resultRdAddress.buildingName = roadAddressData.building_name;
          resultRdAddress.mainBdNo = roadAddressData.main_building_no;
          resultRdAddress.subBdNo = roadAddressData.sub_building_no;
          resultRdAddress.sido = roadAddressData.region_1depth_name;
          resultRdAddress.sigungu = roadAddressData.region_2depth_name;
          resultRdAddress.dong = roadAddressData.region_3depth_name;
          resultRdAddress.roadName = roadAddressData.road_name;
          resultRdAddress.underGround = roadAddressData.underground_yn;
          resultRdAddress.lng = roadAddressData.x;
          resultRdAddress.lat = roadAddressData.y;
          resultRdAddress.zoneCode = roadAddressData.zone_no;
        }
        // 주소정보를 기반으로 PNU_CODE 를 만든다.
        const pnuCode = Utils.pnuCodeString(resultJaAddress.bCode, resultJaAddress.mainNo, resultJaAddress.subNo);
        // @ts-ignore
        setKaAddr({jAddr: resultJaAddress, rAddr: resultRdAddress, pnuCode: pnuCode})
        return {jAddr: resultJaAddress, rAddr: resultRdAddress, pnuCode: pnuCode}
      })
      .catch((error: any) => {
        console.log(error);
      })
  }

  // 네이버지도 주소정보
  const getNaverAddr = (e: any, callT: any) => {
    naverMaps.Service.reverseGeocode({
      location: new naverMaps.LatLng(e.latlng._lat, e.latlng._lng),
    }, (status: any, response: any) => {

      if (status !== naverMaps.Service.Status.OK) {
        return false;
      }

      const resultNAddr: NaverMapAddrData = {} as NaverMapAddrData;
      // 지역적으로 주소가 없는 경우도 발생하기 때문에 속성값에 대한 체크를 해줘야 한다.
      const {items} = response.result;
      let addrDetail: any = '';
      let address: string = '';

      if (items.length > 0) {
        addrDetail = items[0].addrdetail;
        address = items[0].address;
      }

      resultNAddr.address = address;
      resultNAddr.country = addrDetail.country;
      resultNAddr.sido = addrDetail.sido;
      resultNAddr.sigungu = addrDetail.sigugun;
      resultNAddr.dong = addrDetail.dongmyun;
      resultNAddr.ri = addrDetail.ri;
      resultNAddr.etc = addrDetail.rest;
      resultNAddr.lat = (e.latlng._lat).toString();
      resultNAddr.lng = (e.latlng._lng).toString();

      setNaAddr(resultNAddr);
      callT(resultNAddr)
    })
  }


  const getCampData = () : void => {
    if (map === '' || map === undefined) {
      console.log('Map loading..... ')
      return
    }
    // isMyMarker ? getMyCampMarkerData() : getAllCampMarkerData()
    getAllCampMarkerData()
    isPublicMarker && getPublicMarkerData()
  }

  const getAllCampMarkerData = () : void => {
    // console.log('전체 캠핑장')
    Api.getCampMarker_API(map, zoom, diaryType.code).then((res: any): void => {
      // console.log('res >>>>>', res)
      const {data} = res.data;
      const campMarkerList = data && data.length > 0 ? data.map((item: any): MarkerItem => {
        const tempObj: MarkerItem = {} as MarkerItem;
        tempObj.campId = item.camp_id;
        tempObj.userId = item.user_id;
        tempObj.publicType = item.public_type;
        tempObj.photo = '';
        tempObj.lat = item.lat;
        tempObj.lng = item.lng;
        return tempObj
      }) : []

      setMarkerData(campMarkerList)
    })
  }

  // // API 삭제 예정 통합 진행중
  // const getMyCampMarkerData = () : void => {
  //   // console.log('나의 캠핑장')
  //   Api.getMyCampMarker_API(map, zoom, 'ALL').then((res: any): void => {
  //     // console.log(res)
  //     if(res.status === 401) {
  //       // console.log('인증 실패')
  //       setMarkerData([])
  //       return
  //     }
  //     const {data} = res.data;
  //     const campMarkerList = data && data.length > 0 ? data.map((item: any): MarkerItem => {
  //       const tempObj: MarkerItem = {} as MarkerItem;
  //       tempObj.campId = item.camp_id;
  //       tempObj.userId = item.user_id;
  //       tempObj.publicType = item.public_type;
  //       tempObj.photo = '';
  //       tempObj.lat = item.lat;
  //       tempObj.lng = item.lng;
  //       return tempObj
  //     }) : []
  //
  //     setMarkerData(campMarkerList)
  //   })
  // }

  const getPublicMarkerData = async () : Promise<void> => {
    const resData = await Api.getPublicCampMarker_API(map, zoom, 'ALL')
    const { data } = resData.data
    // console.log('getPublicMarkerData >>>>>', data)
    const publicCampList = data && data.length > 0 ? data.map((item: any): PublicMarkerItem => {
      const tempObj: PublicMarkerItem = {} as PublicMarkerItem;
      tempObj.contentId = item.contentId;
      tempObj.markerType = item.markerType ? item.markerType : 'UNIT';
      tempObj.originType = item.originType ? item.originType : 'SD';   // SD : 시도, SG: 시군구
      tempObj.title = item.title ? item.title : '';
      tempObj.photo = '';
      tempObj.data = item;
      tempObj.lat = item.lat;
      tempObj.lng = item.lng;
      return tempObj
    }) : []
    setPublicMarkerData(publicCampList)
  }

  const handleIdleChanged = () => {
    getCampData()
  }

  const handleOnZoomChanged = (e: any) => {
    setZoom(e)
  }

  const handleOnCenterChanged = (e: any) => {
    setCenter({lat: e._lat, lng: e._lng})
  }

  const handleToggleMyCampList = () => {
    setIsMyMarker(!isMyMarker)
  }

  const handleToggleMarkerShow = () => {
    setIsAllMarkerShow(!isAllMarkerShow)
    getCampData()
  };

  const handleTogglePublicMarkerShow = () => {
    setIsPublicMarker(!isPublicMarker)
  }

  const handleMarkerClick = (id: number) => {
    if (isMobile) {
      campDetailCall(id)
    } else {
      // alert(`${id} 가 클릭되었습니다.`)
    }
  }

  const handlePublicMarkerClick = (id: number, type: string = 'UNIT', originType: string = '', originName: string = '' ) => {
    if (isMobile) {
      if(type === 'CLUSTER'){
        // 키와값을 조합해서 보내준다.
        const params = {
          originType: originType,
          originName: originName,
        }
        publicCampClusterCall(params)
      }else{
        publicCampDetailCall(id)
      }
    } else {
      // alert(`${id} 가 클릭되었습니다.`)
    }
  }


  const RegCompModalClose = () => {
    setIsShowRegCamp(false)
    getCampData()
  }


  const ContentsShowToggle = (): void => {

  }


  const handleClickMyLocation = (): void => {
    let locMarker;
    const onSuccessGeolocation = async (position: any) => {
      const latLng = new naverMaps.LatLng(position.coords.latitude, position.coords.longitude)
      await map.setCenter(latLng)
      await map.setZoom(18)
      const markerContent = [
        '<div class="pin"></div>',
        '<div class="pulse"></div>',
      ].join('')
      const MarkerOptions = {
        position: latLng,
        map: map,
        icon: {
          content: markerContent
        }
      };
      locMarker = new naverMaps.Marker(MarkerOptions)
      setMyLocation({
        isMyLocation: true,
        isMyLocationLoaded: false,
        myLocationMarker: locMarker
      })
    };

    const onErrorGeolocation = () => {
      setTimeout(() => {
        alert('내 위치를 사용할 수 없습니다.')
        setMyLocation({
          isMyLocation: false,
          isMyLocationLoaded: false,
          myLocationMarker: ''
        })
      }, 300);
    }

    if (myLocation.isMyLocation) {
      // @ts-ignore
      myLocation.myLocationMarker.setMap(null);
      setMyLocation({isMyLocation: false, isMyLocationLoaded: false, myLocationMarker: ''})
      return
    }
    // @ts-ignore
    setMyLocation({isMyLocation: false, isMyLocationLoaded: true, myLocationMarker: locMarker})

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => onSuccessGeolocation(position),
        () => onErrorGeolocation());
    } else {
      alert('내 위치를 사용할 수 없습니다.')
    }
  };


  useEffect(() => {
    // 지도가 로딩 된후 데이터 호출
    getCampData()
  }, [mapRef]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    getCampData()
  }, [isMyMarker, isPublicMarker, diaryType])


  useEffect(() => {

  }, [])


  useEffect(() => {
    // console.log(zoom)
  },[zoom] )


  return (
    <div className='MapWrapper'>
      <NaverMap
        naverRef={(ref: React.SetStateAction<string>) => setMapRef(ref)}
        id="react-naver-maps-carcamp"
        style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0}}
        center={center}
        defaultZoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        disableDoubleClickZoom={true}
        disableDoubleTapZoom={true}
        onIdle={handleIdleChanged}
        onZoomChanged={(e: any) => handleOnZoomChanged(e)}
        onCenterChanged={(e: any) => handleOnCenterChanged(e)}
        onClick={(e: any) => handleMapClick(e)}
        event={(e: any) => console.log(e)}
      >
        {
          markerData.length > 0 && isAllMarkerShow && markerData.map((m) => {
            return (
              <Markers
                key={m.campId}
                position={naverMaps.LatLng(m.lat, m.lng)}
                onClick={(id) => handleMarkerClick(m.campId)}
                data={m}
                zoom={zoom}
              />
            )
          })
        }
        {
          publicMarkerData.length > 0 && isAllMarkerShow && isPublicMarker && publicMarkerData.map((m) => {
            return (
              <PublicMarkers
                key={m.contentId}
                position={naverMaps.LatLng(m.lat, m.lng)}
                onClick={(id) => handlePublicMarkerClick(Number(m.contentId), m.markerType, m.originType, m.title)}
                data={m}
                markerType={m.markerType}
              />
            )
          })
        }
      </NaverMap>
      <MapControlView
        isAllMarkerShow={isAllMarkerShow}
        isMyLocation={myLocation.isMyLocation}
        isMyLocationLoaded={myLocation.isMyLocationLoaded}
        isCadastralFullLayer={overlayType.isCadastralFullLayer}
        isTotalMarkerShow={totalMarker.isShow}
        isPublicMarker={isPublicMarker}
        isSatellite={isSatellite}
        isMyMarker={isMyMarker}
        diaryType={diaryType}
        funcClickPlus={handleClickPlus}
        funcClickMinus={handleClickMinus}
        funcClickMyLocation={handleClickMyLocation}
        funcToggleCadastral={handleToggleCadastral}
        funcToggleMyCamp={handleToggleMyCampList}
        funcToggleSatelliteLayer={handleToggleSatelliteLayer}
        funcToggleMarkerShow={handleToggleMarkerShow}
        funcToggleContentsShow={ContentsShowToggle}
        funcTogglePublicMarkerShow={handleTogglePublicMarkerShow}
        funcSetDiaryType={setDiaryType}
      />
      <RegCampModal isShow={isShowRegCamp} close={RegCompModalClose} naAddr={naAddr} kaAddr={kaAddr}/>
    </div>
  );
};

export default MapPage;


